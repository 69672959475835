export const municipiosProvinciasComarcas = [
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22006,
    municipioNombre: "Aísa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22028,
    municipioNombre: "Ansó",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22032,
    municipioNombre: "Aragüés del Puerto",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22044,
    municipioNombre: "Bailo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22068,
    municipioNombre: "Borau",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22076,
    municipioNombre: "Canal de Berdún",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22078,
    municipioNombre: "Canfranc",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22086,
    municipioNombre: "Castiello de Jaca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22106,
    municipioNombre: "Fago",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22130,
    municipioNombre: "Jaca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22131,
    municipioNombre: "Jasa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22208,
    municipioNombre: "Santa Cilia",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22209,
    municipioNombre: "Santa Cruz de la Serós",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22250,
    municipioNombre: "Villanúa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22901,
    municipioNombre: "Valle de Hecho",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 22902,
    municipioNombre: "Puente la Reina de Jaca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 50035,
    municipioNombre: "Artieda",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 50168,
    municipioNombre: "Mianos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 50232,
    municipioNombre: "Salvatierra de Esca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Jacetania",
    municipioId: 50245,
    municipioNombre: "Sigüés",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22059,
    municipioNombre: "Biescas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22072,
    municipioNombre: "Caldearenas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22122,
    municipioNombre: "Hoz de Jaca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22170,
    municipioNombre: "Panticosa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22199,
    municipioNombre: "Sabiñánigo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22204,
    municipioNombre: "Sallent de Gállego",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22252,
    municipioNombre: "Yebra de Basa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Alto Gállego",
    municipioId: 22253,
    municipioNombre: "Yésero",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22002,
    municipioNombre: "Abizanda",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22051,
    municipioNombre: "Bárcabo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22057,
    municipioNombre: "Bielsa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22066,
    municipioNombre: "Boltaña",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22069,
    municipioNombre: "Broto",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22107,
    municipioNombre: "Fanlo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22109,
    municipioNombre: "Fiscal",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22113,
    municipioNombre: "Fueva (La)",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22114,
    municipioNombre: "Gistaín",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22133,
    municipioNombre: "Labuerda",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22144,
    municipioNombre: "Laspuña",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22168,
    municipioNombre: "Palo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22182,
    municipioNombre: "Plan",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22189,
    municipioNombre: "Puértolas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22190,
    municipioNombre: "Pueyo de Araguás (El)",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22207,
    municipioNombre: "San Juan de Plan",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22227,
    municipioNombre: "Tella-Sin",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22230,
    municipioNombre: "Torla-Ordesa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sobrarbe",
    municipioId: 22907,
    municipioNombre: "Aínsa-Sobrarbe",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22035,
    municipioNombre: "Arén",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22053,
    municipioNombre: "Benabarre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22054,
    municipioNombre: "Benasque",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22062,
    municipioNombre: "Bisaurri",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22067,
    municipioNombre: "Bonansa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22074,
    municipioNombre: "Campo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22080,
    municipioNombre: "Capella",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22084,
    municipioNombre: "Castejón de Sos",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22087,
    municipioNombre: "Castigaleu",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22095,
    municipioNombre: "Chía",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22105,
    municipioNombre: "Estopiñán del Castillo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22111,
    municipioNombre: "Foradada del Toscar",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22117,
    municipioNombre: "Graus",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22129,
    municipioNombre: "Isábena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22142,
    municipioNombre: "Lascuarre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22143,
    municipioNombre: "Laspaúles",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22155,
    municipioNombre: "Monesma y Cajigar",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22157,
    municipioNombre: "Montanuy",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22177,
    municipioNombre: "Perarrúa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22187,
    municipioNombre: "Puebla de Castro (La)",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22188,
    municipioNombre: "Puente de Montañana",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22200,
    municipioNombre: "Sahún",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22212,
    municipioNombre: "Santaliestra y San Quílez",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22214,
    municipioNombre: "Secastilla",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22215,
    municipioNombre: "Seira",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22221,
    municipioNombre: "Sesué",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22223,
    municipioNombre: "Sopeira",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22229,
    municipioNombre: "Tolva",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22233,
    municipioNombre: "Torre la Ribera",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22243,
    municipioNombre: "Valle de Bardají",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22244,
    municipioNombre: "Valle de Lierp",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22246,
    municipioNombre: "Beranuy",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22247,
    municipioNombre: "Viacamp y Litera",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Ribagorza",
    municipioId: 22249,
    municipioNombre: "Villanova",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50033,
    municipioNombre: "Ardisa",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50036,
    municipioNombre: "Asín",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50041,
    municipioNombre: "Bagüés",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50051,
    municipioNombre: "Biota",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50077,
    municipioNombre: "Castejón de Valdejasa",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50078,
    municipioNombre: "Castiliscar",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50095,
    municipioNombre: "Ejea de los Caballeros",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50100,
    municipioNombre: "Erla",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50109,
    municipioNombre: "Frago (El)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50128,
    municipioNombre: "Isuerre",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50135,
    municipioNombre: "Layana",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50142,
    municipioNombre: "Lobera de Onsella",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50144,
    municipioNombre: "Longás",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50148,
    municipioNombre: "Luesia",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50151,
    municipioNombre: "Luna",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50186,
    municipioNombre: "Navardún",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50197,
    municipioNombre: "Orés",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50205,
    municipioNombre: "Pedrosas (Las)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50207,
    municipioNombre: "Piedratajada",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50210,
    municipioNombre: "Pintanos (Los)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50220,
    municipioNombre: "Puendeluna",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50230,
    municipioNombre: "Sádaba",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50244,
    municipioNombre: "Sierra de Luna",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50248,
    municipioNombre: "Sos del Rey Católico",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50252,
    municipioNombre: "Tauste",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50267,
    municipioNombre: "Uncastillo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50268,
    municipioNombre: "Undués de Lerda",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50270,
    municipioNombre: "Urriés",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50276,
    municipioNombre: "Valpalmas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50901,
    municipioNombre: "Biel",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinco Villas",
    municipioId: 50902,
    municipioNombre: "Marracos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22004,
    municipioNombre: "Agüero",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22011,
    municipioNombre: "Albero Alto",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22014,
    municipioNombre: "Alcalá de Gurrea",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22015,
    municipioNombre: "Alcalá del Obispo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22019,
    municipioNombre: "Alerre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22021,
    municipioNombre: "Almudévar",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22027,
    municipioNombre: "Angüés",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22029,
    municipioNombre: "Antillón",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22036,
    municipioNombre: "Argavieso",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22037,
    municipioNombre: "Arguis",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22039,
    municipioNombre: "Ayerbe",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22047,
    municipioNombre: "Banastás",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22063,
    municipioNombre: "Biscarrués",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22064,
    municipioNombre: "Blecua y Torres",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22081,
    municipioNombre: "Casbas de Huesca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22096,
    municipioNombre: "Chimillas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22119,
    municipioNombre: "Gurrea de Gállego",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22125,
    municipioNombre: "Huesca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22126,
    municipioNombre: "Ibieca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22127,
    municipioNombre: "Igriés",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22149,
    municipioNombre: "Loarre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22150,
    municipioNombre: "Loporzano",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22151,
    municipioNombre: "Loscorrales",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22156,
    municipioNombre: "Monflorite-Lascasas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22162,
    municipioNombre: "Novales",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22163,
    municipioNombre: "Nueno",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22173,
    municipioNombre: "Peñas de Riglos (Las)",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22178,
    municipioNombre: "Pertusa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22181,
    municipioNombre: "Piracés",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22195,
    municipioNombre: "Quicena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22203,
    municipioNombre: "Salillas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22220,
    municipioNombre: "Sesa",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22222,
    municipioNombre: "Siétamo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22228,
    municipioNombre: "Tierz",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22239,
    municipioNombre: "Tramaced",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22248,
    municipioNombre: "Vicién",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22904,
    municipioNombre: "Sotonera (La)",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 22905,
    municipioNombre: "Lupiñén-Ortilla",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 50185,
    municipioNombre: "Murillo de Gállego",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Hoya de Huesca",
    municipioId: 50238,
    municipioNombre: "Santa Eulalia de Gállego",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22001,
    municipioNombre: "Abiego",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22003,
    municipioNombre: "Adahuesca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22024,
    municipioNombre: "Alquézar",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22041,
    municipioNombre: "Azara",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22042,
    municipioNombre: "Azlor",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22048,
    municipioNombre: "Barbastro",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22050,
    municipioNombre: "Barbuñales",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22055,
    municipioNombre: "Berbegal",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22058,
    municipioNombre: "Bierge",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22082,
    municipioNombre: "Castejón del Puente",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22088,
    municipioNombre: "Castillazuelo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22090,
    municipioNombre: "Colungo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22102,
    municipioNombre: "Estada",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22103,
    municipioNombre: "Estadilla",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22115,
    municipioNombre: "Grado (El)",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22128,
    municipioNombre: "Ilche",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22135,
    municipioNombre: "Laluenga",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22139,
    municipioNombre: "Laperdiguera",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22141,
    municipioNombre: "Lascellas-Ponzano",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22160,
    municipioNombre: "Naval",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22164,
    municipioNombre: "Olvena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22174,
    municipioNombre: "Peralta de Alcofea",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22176,
    municipioNombre: "Peraltilla",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22186,
    municipioNombre: "Pozán de Vero",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22201,
    municipioNombre: "Salas Altas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22202,
    municipioNombre: "Salas Bajas",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22235,
    municipioNombre: "Torres de Alcanadre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22906,
    municipioNombre: "Santa María de Dulcis",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Somontano de Barbastro",
    municipioId: 22908,
    municipioNombre: "Hoz y Costeán",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22007,
    municipioNombre: "Albalate de Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22017,
    municipioNombre: "Alcolea de Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22020,
    municipioNombre: "Alfántega",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22022,
    municipioNombre: "Almunia de San Juan",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22060,
    municipioNombre: "Binaced",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22110,
    municipioNombre: "Fonz",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22158,
    municipioNombre: "Monzón",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22193,
    municipioNombre: "Pueyo de Santa Cruz",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cinca Medio",
    municipioId: 22903,
    municipioNombre: "San Miguel del Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22009,
    municipioNombre: "Albelda",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22016,
    municipioNombre: "Alcampell",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22025,
    municipioNombre: "Altorricón",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22040,
    municipioNombre: "Azanuy-Alins",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22043,
    municipioNombre: "Baells",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22045,
    municipioNombre: "Baldellou",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22061,
    municipioNombre: "Binéfar",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22075,
    municipioNombre: "Camporrells",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22089,
    municipioNombre: "Castillonroy",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22099,
    municipioNombre: "Esplús",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22175,
    municipioNombre: "Peralta de Calasanz",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22205,
    municipioNombre: "San Esteban de Litera",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22225,
    municipioNombre: "Tamarite de Litera",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "La Litera",
    municipioId: 22909,
    municipioNombre: "Vencillón",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22008,
    municipioNombre: "Albalatillo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22012,
    municipioNombre: "Albero Bajo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22013,
    municipioNombre: "Alberuela de Tubo",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22018,
    municipioNombre: "Alcubierre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22023,
    municipioNombre: "Almuniente",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22049,
    municipioNombre: "Barbués",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22079,
    municipioNombre: "Capdesaso",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22083,
    municipioNombre: "Castejón de Monegros",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22085,
    municipioNombre: "Castelflorite",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22116,
    municipioNombre: "Grañén",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22124,
    municipioNombre: "Huerto",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22136,
    municipioNombre: "Lalueza",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22137,
    municipioNombre: "Lanaja",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22172,
    municipioNombre: "Peñalba",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22184,
    municipioNombre: "Poleñino",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22197,
    municipioNombre: "Robres",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22206,
    municipioNombre: "Sangarrén",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22213,
    municipioNombre: "Sariñena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22217,
    municipioNombre: "Sena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22218,
    municipioNombre: "Senés de Alcubierre",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22226,
    municipioNombre: "Tardienta",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22232,
    municipioNombre: "Torralba de Aragón",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22236,
    municipioNombre: "Torres de Barbués",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22242,
    municipioNombre: "Valfarta",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 22251,
    municipioNombre: "Villanueva de Sigena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 50022,
    municipioNombre: "Almolda (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 50059,
    municipioNombre: "Bujaraloz",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 50104,
    municipioNombre: "Farlete",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 50137,
    municipioNombre: "Leciñena",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 50170,
    municipioNombre: "Monegrillo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Los Monegros",
    municipioId: 50206,
    municipioNombre: "Perdiguera",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22046,
    municipioNombre: "Ballobar",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22052,
    municipioNombre: "Belver de Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22077,
    municipioNombre: "Candasnos",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22094,
    municipioNombre: "Chalamera",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22112,
    municipioNombre: "Fraga",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22165,
    municipioNombre: "Ontiñena",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22167,
    municipioNombre: "Osso de Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22234,
    municipioNombre: "Torrente de Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22245,
    municipioNombre: "Velilla de Cinca",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 22254,
    municipioNombre: "Zaidín",
    provinciaId: 22,
    provinciaNombre: "Huesca",
  },
  {
    comarcaId: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Cinca",
    municipioId: 50165,
    municipioNombre: "Mequinenza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50003,
    municipioNombre: "Agón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50006,
    municipioNombre: "Ainzón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50010,
    municipioNombre: "Alberite de San Juan",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50011,
    municipioNombre: "Albeta",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50027,
    municipioNombre: "Ambel",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50052,
    municipioNombre: "Bisimbre",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50055,
    municipioNombre: "Borja",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50060,
    municipioNombre: "Bulbuente",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50061,
    municipioNombre: "Bureta",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50111,
    municipioNombre: "Fréscano",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50113,
    municipioNombre: "Fuendejalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50153,
    municipioNombre: "Magallón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50156,
    municipioNombre: "Maleján",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50160,
    municipioNombre: "Mallén",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50191,
    municipioNombre: "Novillas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50216,
    municipioNombre: "Pozuelo de Aragón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50249,
    municipioNombre: "Tabuenca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Borja",
    municipioId: 50250,
    municipioNombre: "Talamantes",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50031,
    municipioNombre: "Aranda de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50057,
    municipioNombre: "Brea de Aragón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50069,
    municipioNombre: "Calcena",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50121,
    municipioNombre: "Gotor",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50126,
    municipioNombre: "Illueca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50130,
    municipioNombre: "Jarque de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50166,
    municipioNombre: "Mesones de Isuela",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50198,
    municipioNombre: "Oseja",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50214,
    municipioNombre: "Pomer",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50221,
    municipioNombre: "Purujosa",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50243,
    municipioNombre: "Sestrica",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50254,
    municipioNombre: "Tierga",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Aranda",
    municipioId: 50266,
    municipioNombre: "Trasobares",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50008,
    municipioNombre: "Alagón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50013,
    municipioNombre: "Alcalá de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50043,
    municipioNombre: "Bárboles",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50053,
    municipioNombre: "Boquiñeni",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50064,
    municipioNombre: "Cabañas de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50107,
    municipioNombre: "Figueruelas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50118,
    municipioNombre: "Gallur",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50123,
    municipioNombre: "Grisén",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50132,
    municipioNombre: "Joyosa (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50147,
    municipioNombre: "Luceni",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50204,
    municipioNombre: "Pedrola",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50209,
    municipioNombre: "Pinseque",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50212,
    municipioNombre: "Pleitas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50217,
    municipioNombre: "Pradilla de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50223,
    municipioNombre: "Remolinos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50247,
    municipioNombre: "Sobradiel",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Alta del Ebro",
    municipioId: 50262,
    municipioNombre: "Torres de Berrellén",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50024,
    municipioNombre: "Almonacid de la Sierra",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50025,
    municipioNombre: "Almunia de Doña Godina (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50026,
    municipioNombre: "Alpartir",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50044,
    municipioNombre: "Bardallur",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50068,
    municipioNombre: "Calatorao",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50093,
    municipioNombre: "Chodes",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50099,
    municipioNombre: "Épila",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50146,
    municipioNombre: "Lucena de Jalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50150,
    municipioNombre: "Lumpiaque",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50175,
    municipioNombre: "Morata de Jalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50182,
    municipioNombre: "Muela (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50211,
    municipioNombre: "Plasencia de Jalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50225,
    municipioNombre: "Ricla",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50228,
    municipioNombre: "Rueda de Jalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50231,
    municipioNombre: "Salillas de Jalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50236,
    municipioNombre: "Santa Cruz de Grío",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Valdejalón",
    municipioId: 50269,
    municipioNombre: "Urrea de Jalón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50017,
    municipioNombre: "Alfajarín",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50056,
    municipioNombre: "Botorrita",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50062,
    municipioNombre: "Burgo de Ebro (El)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50066,
    municipioNombre: "Cadrete",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50089,
    municipioNombre: "Cuarte de Huerva",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50115,
    municipioNombre: "Fuentes de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50131,
    municipioNombre: "Jaulín",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50163,
    municipioNombre: "María de Huerva",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50164,
    municipioNombre: "Mediana de Aragón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50180,
    municipioNombre: "Mozota",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50193,
    municipioNombre: "Nuez de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50199,
    municipioNombre: "Osera de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50203,
    municipioNombre: "Pastriz",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50219,
    municipioNombre: "Puebla de Alfindén (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50235,
    municipioNombre: "San Mateo de Gállego",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50272,
    municipioNombre: "Utebo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50285,
    municipioNombre: "Villafranca de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50288,
    municipioNombre: "Villanueva de Gállego",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "34780fd8-17ce-11ed-861d-0242ac120002",
    comarcaNombre: "Zaragoza (Ciudad)",
    municipioId: 50297,
    municipioNombre: "Zaragoza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50298,
    municipioNombre: "Zuera",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comarca Central (excepto Zaragoza ciudad)",
    municipioId: 50903,
    municipioNombre: "Villamayor de Gállego",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50012,
    municipioNombre: "Alborge",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50019,
    municipioNombre: "Alforque",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50083,
    municipioNombre: "Cinco Olivas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50101,
    municipioNombre: "Escatrón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50119,
    municipioNombre: "Gelsa",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50208,
    municipioNombre: "Pina de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50222,
    municipioNombre: "Quinto",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50240,
    municipioNombre: "Sástago",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50278,
    municipioNombre: "Velilla de Ebro",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Ribera Baja del Ebro",
    municipioId: 50296,
    municipioNombre: "Zaida (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44004,
    municipioNombre: "Aguaviva",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44013,
    municipioNombre: "Alcañiz",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44014,
    municipioNombre: "Alcorisa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44038,
    municipioNombre: "Belmonte de San José",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44040,
    municipioNombre: "Berge",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44051,
    municipioNombre: "Calanda",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44061,
    municipioNombre: "Cañada de Verich (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44068,
    municipioNombre: "Castelserás",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44077,
    municipioNombre: "Cerollera (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44080,
    municipioNombre: "Codoñera (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44107,
    municipioNombre: "Foz-Calanda",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44118,
    municipioNombre: "Ginebrosa (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44145,
    municipioNombre: "Mas de las Matas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44146,
    municipioNombre: "Mata de los Olmos (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44173,
    municipioNombre: "Olmos (Los)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44178,
    municipioNombre: "Parras de Castellote (Las)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44212,
    municipioNombre: "Seno",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44221,
    municipioNombre: "Torrecilla de Alcañiz",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44230,
    municipioNombre: "Torrevelilla",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Aragón",
    municipioId: 44241,
    municipioNombre: "Valdealgorfa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50001,
    municipioNombre: "Abanto",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50009,
    municipioNombre: "Alarba",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50015,
    municipioNombre: "Alconchel de Ariza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50020,
    municipioNombre: "Alhama de Aragón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50029,
    municipioNombre: "Aniñón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50032,
    municipioNombre: "Arándiga",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50034,
    municipioNombre: "Ariza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50038,
    municipioNombre: "Ateca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50046,
    municipioNombre: "Belmonte de Gracián",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50047,
    municipioNombre: "Berdejo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50050,
    municipioNombre: "Bijuesca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50054,
    municipioNombre: "Bordalba",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50058,
    municipioNombre: "Bubierca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50065,
    municipioNombre: "Cabolafuente",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42ddcde0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Calatayud (Ciudad)",
    municipioId: 50067,
    municipioNombre: "Calatayud",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50070,
    municipioNombre: "Calmarza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50071,
    municipioNombre: "Campillo de Aragón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50072,
    municipioNombre: "Carenas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50075,
    municipioNombre: "Castejón de Alarba",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50076,
    municipioNombre: "Castejón de las Armas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50079,
    municipioNombre: "Cervera de la Cañada",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50081,
    municipioNombre: "Cetina",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50082,
    municipioNombre: "Cimballa",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50084,
    municipioNombre: "Clarés de Ribota",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50086,
    municipioNombre: "Codos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50087,
    municipioNombre: "Contamina",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50096,
    municipioNombre: "Embid de Ariza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50110,
    municipioNombre: "Frasno (El)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50116,
    municipioNombre: "Fuentes de Jiloca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50120,
    municipioNombre: "Godojos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50125,
    municipioNombre: "Ibdes",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50129,
    municipioNombre: "Jaraba",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50155,
    municipioNombre: "Malanquilla",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50159,
    municipioNombre: "Maluenda",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50162,
    municipioNombre: "Mara",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50169,
    municipioNombre: "Miedes de Aragón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50172,
    municipioNombre: "Monreal de Ariza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50173,
    municipioNombre: "Monterde",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50174,
    municipioNombre: "Montón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50176,
    municipioNombre: "Morata de Jiloca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50177,
    municipioNombre: "Morés",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50178,
    municipioNombre: "Moros",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50183,
    municipioNombre: "Munébrega",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50187,
    municipioNombre: "Nigüella",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50192,
    municipioNombre: "Nuévalos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50194,
    municipioNombre: "Olvés",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50196,
    municipioNombre: "Orera",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50201,
    municipioNombre: "Paracuellos de Jiloca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50202,
    municipioNombre: "Paracuellos de la Ribera",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50215,
    municipioNombre: "Pozuel de Ariza",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50229,
    municipioNombre: "Ruesca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50241,
    municipioNombre: "Saviñán",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50242,
    municipioNombre: "Sediles",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50246,
    municipioNombre: "Sisamón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50253,
    municipioNombre: "Terrer",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50255,
    municipioNombre: "Tobed",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50257,
    municipioNombre: "Torralba de Ribota",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50259,
    municipioNombre: "Torrehermosa",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50260,
    municipioNombre: "Torrelapaja",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50263,
    municipioNombre: "Torrijo de la Cañada",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50277,
    municipioNombre: "Valtorres",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50279,
    municipioNombre: "Velilla de Jiloca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50282,
    municipioNombre: "Vilueña (La)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50284,
    municipioNombre: "Villafeliche",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50286,
    municipioNombre: "Villalba de Perejil",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50287,
    municipioNombre: "Villalengua",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Calatayud",
    municipioId: 50293,
    municipioNombre: "Villarroya de la Sierra",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50021,
    municipioNombre: "Almochuel",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50023,
    municipioNombre: "Almonacid de la Cuba",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50039,
    municipioNombre: "Azuara",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50045,
    municipioNombre: "Belchite",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50085,
    municipioNombre: "Codo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50114,
    municipioNombre: "Fuendetodos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50133,
    municipioNombre: "Lagata",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50136,
    municipioNombre: "Lécera",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50139,
    municipioNombre: "Letux",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50171,
    municipioNombre: "Moneva",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50179,
    municipioNombre: "Moyuela",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50213,
    municipioNombre: "Plenas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50218,
    municipioNombre: "Puebla de Albortón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50233,
    municipioNombre: "Samper del Salz",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Belchite",
    municipioId: 50275,
    municipioNombre: "Valmadrid",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44008,
    municipioNombre: "Albalate del Arzobispo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44031,
    municipioNombre: "Azaila",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44067,
    municipioNombre: "Castelnou",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44122,
    municipioNombre: "Híjar",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44129,
    municipioNombre: "Jatiel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44191,
    municipioNombre: "Puebla de Híjar (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44205,
    municipioNombre: "Samper de Calanda",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44237,
    municipioNombre: "Urrea de Gaén",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Bajo Martín",
    municipioId: 44265,
    municipioNombre: "Vinaceite",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50002,
    municipioNombre: "Acered",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50016,
    municipioNombre: "Aldehuela de Liestos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50028,
    municipioNombre: "Anento",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50037,
    municipioNombre: "Atea",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50040,
    municipioNombre: "Badules",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50042,
    municipioNombre: "Balconchán",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50048,
    municipioNombre: "Berrueco",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50080,
    municipioNombre: "Cerveruela",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50090,
    municipioNombre: "Cubel",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50091,
    municipioNombre: "Cuerlas (Las)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50094,
    municipioNombre: "Daroca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50108,
    municipioNombre: "Fombuena",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50117,
    municipioNombre: "Gallocanta",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50124,
    municipioNombre: "Herrera de los Navarros",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50134,
    municipioNombre: "Langa del Castillo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50138,
    municipioNombre: "Lechón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50149,
    municipioNombre: "Luesma",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50154,
    municipioNombre: "Mainar",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50161,
    municipioNombre: "Manchones",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50184,
    municipioNombre: "Murero",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50188,
    municipioNombre: "Nombrevilla",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50195,
    municipioNombre: "Orcajo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50224,
    municipioNombre: "Retascón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50227,
    municipioNombre: "Romanos",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50239,
    municipioNombre: "Santed",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50256,
    municipioNombre: "Torralba de los Frailes",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50258,
    municipioNombre: "Torralbilla",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50271,
    municipioNombre: "Used",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50273,
    municipioNombre: "Valdehorna",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50274,
    municipioNombre: "Val de San Martín",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50283,
    municipioNombre: "Villadoz",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50289,
    municipioNombre: "Villanueva de Jiloca",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50291,
    municipioNombre: "Villar de los Navarros",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50292,
    municipioNombre: "Villarreal de Huerva",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Campo de Daroca",
    municipioId: 50294,
    municipioNombre: "Villarroya del Campo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44023,
    municipioNombre: "Allueva",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44032,
    municipioNombre: "Bádenas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44033,
    municipioNombre: "Báguena",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44034,
    municipioNombre: "Bañón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44035,
    municipioNombre: "Barrachina",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44036,
    municipioNombre: "Bea",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44039,
    municipioNombre: "Bello",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44042,
    municipioNombre: "Blancas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44046,
    municipioNombre: "Bueña",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44047,
    municipioNombre: "Burbáguena",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44050,
    municipioNombre: "Calamocha",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44056,
    municipioNombre: "Caminreal",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44065,
    municipioNombre: "Castejón de Tornos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44085,
    municipioNombre: "Cosa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44090,
    municipioNombre: "Cucalón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44101,
    municipioNombre: "Ferreruela de Huerva",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44102,
    municipioNombre: "Fonfría",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44112,
    municipioNombre: "Fuentes Claras",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44132,
    municipioNombre: "Lagueruela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44133,
    municipioNombre: "Lanzuela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44138,
    municipioNombre: "Loscos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44152,
    municipioNombre: "Monforte de Moyuela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44153,
    municipioNombre: "Monreal del Campo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44164,
    municipioNombre: "Nogueras",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44168,
    municipioNombre: "Odón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44169,
    municipioNombre: "Ojos Negros",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44180,
    municipioNombre: "Peracense",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44190,
    municipioNombre: "Pozuel del Campo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44200,
    municipioNombre: "Rubielos de la Cérida",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44207,
    municipioNombre: "San Martín del Río",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44208,
    municipioNombre: "Santa Cruz de Nogueras",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44213,
    municipioNombre: "Singra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44219,
    municipioNombre: "Tornos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44220,
    municipioNombre: "Torralba de los Sisones",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44222,
    municipioNombre: "Torrecilla del Rebollar",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44227,
    municipioNombre: "Torre los Negros",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44232,
    municipioNombre: "Torrijo del Campo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44251,
    municipioNombre: "Villafranca del Campo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44252,
    municipioNombre: "Villahermosa del Campo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Jiloca",
    municipioId: 44258,
    municipioNombre: "Villar del Salz",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44011,
    municipioNombre: "Alcaine",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44017,
    municipioNombre: "Aliaga",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44024,
    municipioNombre: "Anadón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44043,
    municipioNombre: "Blesa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44063,
    municipioNombre: "Cañizar del Olivar",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44066,
    municipioNombre: "Castel de Cabra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44084,
    municipioNombre: "Cortes de Aragón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44093,
    municipioNombre: "Cuevas de Almudén",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44099,
    municipioNombre: "Escucha",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44110,
    municipioNombre: "Fuenferrada",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44123,
    municipioNombre: "Hinojosa de Jarque",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44124,
    municipioNombre: "Hoz de la Vieja (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44125,
    municipioNombre: "Huesa del Común",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44128,
    municipioNombre: "Jarque de la Val",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44131,
    municipioNombre: "Josa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44142,
    municipioNombre: "Maicas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44144,
    municipioNombre: "Martín del Río",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44148,
    municipioNombre: "Mezquita de Jarque",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44155,
    municipioNombre: "Montalbán",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44161,
    municipioNombre: "Muniesa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44167,
    municipioNombre: "Obón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44176,
    municipioNombre: "Palomar de Arroyos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44184,
    municipioNombre: "Plou",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44203,
    municipioNombre: "Salcedillo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44211,
    municipioNombre: "Segura de los Baños",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44224,
    municipioNombre: "Torre de las Arcas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44238,
    municipioNombre: "Utrillas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44256,
    municipioNombre: "Villanueva del Rebollar de la Sierra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44267,
    municipioNombre: "Vivel del Río Martín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Cuencas Mineras",
    municipioId: 44268,
    municipioNombre: "Zoma (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44006,
    municipioNombre: "Alacón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44022,
    municipioNombre: "Alloza",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44025,
    municipioNombre: "Andorra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44029,
    municipioNombre: "Ariño",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44087,
    municipioNombre: "Crivillén",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44096,
    municipioNombre: "Ejulve",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44100,
    municipioNombre: "Estercuel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44116,
    municipioNombre: "Gargallo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Andorra-Sierra de Arcos",
    municipioId: 44172,
    municipioNombre: "Oliete",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44001,
    municipioNombre: "Ababuj",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44003,
    municipioNombre: "Aguatón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44005,
    municipioNombre: "Aguilar del Alfambra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44007,
    municipioNombre: "Alba",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44016,
    municipioNombre: "Alfambra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44018,
    municipioNombre: "Almohaja",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44019,
    municipioNombre: "Alobras",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44020,
    municipioNombre: "Alpeñés",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44028,
    municipioNombre: "Argente",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44053,
    municipioNombre: "Camañas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44055,
    municipioNombre: "Camarillas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44062,
    municipioNombre: "Cañada Vellida",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44064,
    municipioNombre: "Cascante del Río",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44074,
    municipioNombre: "Cedrillas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44075,
    municipioNombre: "Celadas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44076,
    municipioNombre: "Cella",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44082,
    municipioNombre: "Corbalán",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44089,
    municipioNombre: "Cubla",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44092,
    municipioNombre: "Cuervo (El)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44094,
    municipioNombre: "Cuevas Labradas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44097,
    municipioNombre: "Escorihuela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44111,
    municipioNombre: "Fuentes Calientes",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44115,
    municipioNombre: "Galve",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44130,
    municipioNombre: "Jorcas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44135,
    municipioNombre: "Libros",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44136,
    municipioNombre: "Lidón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44156,
    municipioNombre: "Monteagudo del Castillo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44175,
    municipioNombre: "Orrios",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44177,
    municipioNombre: "Pancrudo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44181,
    municipioNombre: "Peralejos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44182,
    municipioNombre: "Perales del Alfambra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44185,
    municipioNombre: "Pobo (El)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44195,
    municipioNombre: "Rillo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44196,
    municipioNombre: "Riodeva",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44209,
    municipioNombre: "Santa Eulalia",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44216,
    municipioNombre: "Teruel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44218,
    municipioNombre: "Tormón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44226,
    municipioNombre: "Torrelacárcel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44228,
    municipioNombre: "Torremocha de Jiloca",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44234,
    municipioNombre: "Tramacastiel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44239,
    municipioNombre: "Valacloche",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44250,
    municipioNombre: "Veguillas de la Sierra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44261,
    municipioNombre: "Villarquemado",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44263,
    municipioNombre: "Villastar",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44264,
    municipioNombre: "Villel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Comunidad de Teruel",
    municipioId: 44266,
    municipioNombre: "Visiedo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44021,
    municipioNombre: "Allepuz",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44044,
    municipioNombre: "Bordón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44059,
    municipioNombre: "Cantavieja",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44060,
    municipioNombre: "Cañada de Benatanduz",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44071,
    municipioNombre: "Castellote",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44088,
    municipioNombre: "Cuba (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44106,
    municipioNombre: "Fortanete",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44126,
    municipioNombre: "Iglesuela del Cid (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44149,
    municipioNombre: "Mirambel",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44150,
    municipioNombre: "Miravete de la Sierra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44151,
    municipioNombre: "Molinos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44183,
    municipioNombre: "Pitarque",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44236,
    municipioNombre: "Tronchón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44260,
    municipioNombre: "Villarluengo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Maestrazgo",
    municipioId: 44262,
    municipioNombre: "Villarroya de los Pinares",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44009,
    municipioNombre: "Albarracín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44041,
    municipioNombre: "Bezas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44045,
    municipioNombre: "Bronchales",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44052,
    municipioNombre: "Calomarde",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44109,
    municipioNombre: "Frías de Albarracín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44117,
    municipioNombre: "Gea de Albarracín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44119,
    municipioNombre: "Griegos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44120,
    municipioNombre: "Guadalaviar",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44127,
    municipioNombre: "Jabaloyas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44157,
    municipioNombre: "Monterde de Albarracín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44159,
    municipioNombre: "Moscardón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44163,
    municipioNombre: "Noguera de Albarracín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44174,
    municipioNombre: "Orihuela del Tremedal",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44189,
    municipioNombre: "Pozondón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44197,
    municipioNombre: "Ródenas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44198,
    municipioNombre: "Royuela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44199,
    municipioNombre: "Rubiales",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44204,
    municipioNombre: "Saldón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44215,
    municipioNombre: "Terriente",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44217,
    municipioNombre: "Toril y Masegoso",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44229,
    municipioNombre: "Torres de Albarracín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44235,
    municipioNombre: "Tramacastilla",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44243,
    municipioNombre: "Valdecuenca",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44249,
    municipioNombre: "Vallecillo (El)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Sierra de Albarracín",
    municipioId: 44257,
    municipioNombre: "Villar del Cobo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44002,
    municipioNombre: "Abejuela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44010,
    municipioNombre: "Albentosa",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44012,
    municipioNombre: "Alcalá de la Selva",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44026,
    municipioNombre: "Arcos de las Salinas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44048,
    municipioNombre: "Cabra de Mora",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44054,
    municipioNombre: "Camarena de la Sierra",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44070,
    municipioNombre: "Castellar (El)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44103,
    municipioNombre: "Formiche Alto",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44113,
    municipioNombre: "Fuentes de Rubielos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44121,
    municipioNombre: "Gúdar",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44137,
    municipioNombre: "Linares de Mora",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44143,
    municipioNombre: "Manzanera",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44158,
    municipioNombre: "Mora de Rubielos",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44160,
    municipioNombre: "Mosqueruela",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44165,
    municipioNombre: "Nogueruelas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44171,
    municipioNombre: "Olba",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44192,
    municipioNombre: "Puebla de Valverde (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44193,
    municipioNombre: "Puertomingalvo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44201,
    municipioNombre: "Rubielos de Mora",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44206,
    municipioNombre: "San Agustín",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44210,
    municipioNombre: "Sarrión",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44231,
    municipioNombre: "Torrijas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44240,
    municipioNombre: "Valbona",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Gúdar-Javalambre",
    municipioId: 44244,
    municipioNombre: "Valdelinares",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44027,
    municipioNombre: "Arens de Lledó",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44037,
    municipioNombre: "Beceite",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44049,
    municipioNombre: "Calaceite",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44086,
    municipioNombre: "Cretas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44105,
    municipioNombre: "Fórnoles",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44108,
    municipioNombre: "Fresneda (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44114,
    municipioNombre: "Fuentespalda",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44141,
    municipioNombre: "Lledó",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44147,
    municipioNombre: "Mazaleón",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44154,
    municipioNombre: "Monroyo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44179,
    municipioNombre: "Peñarroya de Tastavins",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44187,
    municipioNombre: "Portellada (La)",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44194,
    municipioNombre: "Ráfales",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44223,
    municipioNombre: "Torre de Arcas",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44225,
    municipioNombre: "Torre del Compte",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44245,
    municipioNombre: "Valdeltormo",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44246,
    municipioNombre: "Valderrobres",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Matarraña",
    municipioId: 44247,
    municipioNombre: "Valjunquera",
    provinciaId: 44,
    provinciaNombre: "Teruel",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50014,
    municipioNombre: "Alcalá de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50030,
    municipioNombre: "Añón de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50063,
    municipioNombre: "Buste (El)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50106,
    municipioNombre: "Fayos (Los)",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50122,
    municipioNombre: "Grisel",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50140,
    municipioNombre: "Litago",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50141,
    municipioNombre: "Lituénigo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50157,
    municipioNombre: "Malón",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50190,
    municipioNombre: "Novallas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50234,
    municipioNombre: "San Martín de la Virgen de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50237,
    municipioNombre: "Santa Cruz de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50251,
    municipioNombre: "Tarazona",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50261,
    municipioNombre: "Torrellas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50265,
    municipioNombre: "Trasmoz",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50280,
    municipioNombre: "Vera de Moncayo",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
  {
    comarcaId: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    comarcaNombre: "Tarazona y el Moncayo",
    municipioId: 50281,
    municipioNombre: "Vierlas",
    provinciaId: 50,
    provinciaNombre: "Zaragoza",
  },
];

export const municipios = municipiosProvinciasComarcas
  .map((i) => {
    return {
      municipioId: i.municipioId,
      municipioNombre: i.municipioNombre,
    };
  })
  .sort(function (a, b) {
    return a.municipioNombre.localeCompare(b.municipioNombre);
  });

const comarcas = [
  {
    id: "42dbab20-5f69-11ec-b117-a50f8fcc1374",
    text: "Alto Gállego",
  },
  {
    id: "42dda6f2-5f69-11ec-b117-a50f8fcc1374",
    text: "Andorra-Sierra de Arcos",
  },
  {
    id: "42dcbc91-5f69-11ec-b117-a50f8fcc1374",
    text: "Aranda",
  },
  {
    id: "42ddce00-5f69-11ec-b117-a50f8fcc1374",
    text: "Bajo Aragón",
  },
  /*{
    id: "42dd31c0-5f69-11ec-b117-a50f8fcc1374",
    text: "Bajo Aragón-Caspe",
  },*/
  {
    id: "42dc9580-5f69-11ec-b117-a50f8fcc1374",
    text: "Bajo Cinca",
  },
  {
    id: "42dd7fe0-5f69-11ec-b117-a50f8fcc1374",
    text: "Bajo Martín",
  },
  {
    id: "42ddcde0-5f69-11ec-b117-a50f8fcc1374",
    text: "Calatayud (Ciudad)",
  },
  {
    id: "42dd58d1-5f69-11ec-b117-a50f8fcc1374",
    text: "Campo de Belchite",
  },
  {
    id: "42dcbc90-5f69-11ec-b117-a50f8fcc1374",
    text: "Campo de Borja",
  },
  /*{
    id: "42dd58d0-5f69-11ec-b117-a50f8fcc1374",
    text: "Campo de Cariñena",
  },*/
  {
    id: "42dd7fe1-5f69-11ec-b117-a50f8fcc1374",
    text: "Campo de Daroca",
  },
  {
    id: "42dc4760-5f69-11ec-b117-a50f8fcc1374",
    text: "Cinca Medio",
  },
  {
    id: "42dbf940-5f69-11ec-b117-a50f8fcc1374",
    text: "Cinco Villas",
  },
  {
    id: "42dd0ab0-5f69-11ec-b117-a50f8fcc1374",
    text: "Comarca Central (excepto Zaragoza ciudad)",
  },
  {
    id: "42dd31c1-5f69-11ec-b117-a50f8fcc1374",
    text: "Comunidad de Calatayud",
  },
  {
    id: "42ddce01-5f69-11ec-b117-a50f8fcc1374",
    text: "Comunidad de Teruel",
  },
  {
    id: "42dda6f1-5f69-11ec-b117-a50f8fcc1374",
    text: "Cuencas Mineras",
  },
  {
    id: "42de1c20-5f69-11ec-b117-a50f8fcc1374",
    text: "Gúdar-Javalambre",
  },
  {
    id: "42dc2050-5f69-11ec-b117-a50f8fcc1374",
    text: "Hoya de Huesca",
  },
  {
    id: "42dda6f0-5f69-11ec-b117-a50f8fcc1374",
    text: "Jiloca",
  },
  {
    id: "42dae7d0-5f69-11ec-b117-a50f8fcc1374",
    text: "La Jacetania",
  },
  {
    id: "42dc6e70-5f69-11ec-b117-a50f8fcc1374",
    text: "La Litera",
  },
  {
    id: "42dbd231-5f69-11ec-b117-a50f8fcc1374",
    text: "La Ribagorza",
  },
  {
    id: "42dc6e71-5f69-11ec-b117-a50f8fcc1374",
    text: "Los Monegros",
  },
  {
    id: "42ddf510-5f69-11ec-b117-a50f8fcc1374",
    text: "Maestrazgo",
  },
  {
    id: "42de1c21-5f69-11ec-b117-a50f8fcc1374",
    text: "Matarraña",
  },
  {
    id: "42dce3a0-5f69-11ec-b117-a50f8fcc1374",
    text: "Ribera Alta del Ebro",
  },
  {
    id: "42dd0ab1-5f69-11ec-b117-a50f8fcc1374",
    text: "Ribera Baja del Ebro",
  },
  {
    id: "42ddf511-5f69-11ec-b117-a50f8fcc1374",
    text: "Sierra de Albarracín",
  },
  {
    id: "42dbd230-5f69-11ec-b117-a50f8fcc1374",
    text: "Sobrarbe",
  },
  {
    id: "42dc2051-5f69-11ec-b117-a50f8fcc1374",
    text: "Somontano de Barbastro",
  },
  {
    id: "42dc9581-5f69-11ec-b117-a50f8fcc1374",
    text: "Tarazona y el Moncayo",
  },
  {
    id: "42dce3a1-5f69-11ec-b117-a50f8fcc1374",
    text: "Valdejalón",
  },
  {
    id: "34780fd8-17ce-11ed-861d-0242ac120002",
    text: "Zaragoza (Ciudad)",
  },
];

export default comarcas;
